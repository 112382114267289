<template>
  <div>
    <fieldset>
        <legend>Comentarios</legend>
        <div v-if="comentarios_paginados.length > 0">

          <div v-for="(comentario, index) in comentarios_paginados" :key="comentario.id">
            <div class="mb-2" v-if="comentario.tipo ==2">
              <div class="border border">
                <p class="m-1 text-justify"> {{ comentario.comentario }}</p>  
              </div>
              <div class="border back-ground-comentario-sistema">
                <p class="m-1 text-justify font-nombre"> {{ (index + 1) +'.-'}} Sistema  {{ $moment(comentario.created_at).locale('es').format('DD MMM YYYY hh:mm:ss') }}</p>  
              </div>              
            </div> 
            <div  class="mb-2" v-if="comentario.tipo ==1">
              <div class="border border col-9">
                <p class="m-1 text-justify"> {{ comentario.comentario }}</p>  
              </div>
              <div class="border top-0 back-ground-comentario-administrador col-9">
                <p class="m-1 text-left font-nombre text-light"> {{ (index + 1) +'.-'}} {{ comentario.operador_nombre }}  {{ $moment(comentario.created_at).locale('es').format('DD MMM YYYY hh:mm:ss') }}</p>   
              </div>              
            </div>  
            <div class="mb-2" v-if="comentario.tipo ==0">
              <div class="border border col-9 offset-3">
                <p class="m-1 text-justify"> {{ comentario.comentario }}</p>  
              </div>
              <div class="border top-0 back-ground-comentario-cliente col-9 offset-3">
                <p class="m-1 text-left font-nombre"> {{ (index + 1) +'.-'}} {{ comentario.cliente_nombre }}  {{ $moment(comentario.created_at).locale('es').format('DD MMM YYYY hh:mm:ss') }}</p>    
              </div>              
            </div> 
          </div>
          <div v-if="paginas_mostrar.length > 1" class="row col-sm-12 mt-1">
            <nav>
              <ul class="pagination pagination-sm">
                <li class="page-item"><a href="#" class="page-link" @click="pagina_anterior">Anterior</a></li>
                <div v-for="num_pagina in paginas_mostrar" :key="num_pagina.id">
                  <li :class="'page-item'+(num_pagina == pagina_actual ? ' pagina_actual' : '')"><a href="#" class="page-link" @click="pagina_actual = num_pagina">{{num_pagina}}</a></li>
                </div>
                <li class="page-item"><a class="page-link" href="#" @click="pagina_siguiente">Siguiente</a></li>
              </ul>
            </nav>
          </div>          
        </div>

        <div v-else class="text-center">
          <h4>No hay comentarios aún</h4>
        </div>
               
    </fieldset>
  </div>
</template>

<script>
import apiClientes from '@/apps/clientes/api/clientes';
import config from "@/apps/clientes/config";

export default {
  props:{
    ticket:null,
    nuevo_comentario:null
  },
  data(){
    return{
      comentario:null,
      pagina_actual:1,
      por_pagina:4,
      comentarios:[],
    }
  },
  methods:{
    async obtener_comentarios(){
      this.comentarios = (await apiClientes.listar_comentarios_tickets('ticket_id', this.ticket.id)).data;
      // this.$log.info('comentarios',this.comentarios);
    },
    async agregar_comentario(){
        try {
            this.ticket.comentario = this.comentario;
            this.ticket.origen = config.comentarios.origen_administrador;
            this.ticket.tipo = config.comentarios.para_cliente;

            await apiClientes.enviar_comentario_tickets(this.ticket.id, this.ticket);

            this.comentario = null;
            this.$emit('actualizar', this.ticket.id);
            this.$helper.showMessage('Comentario agregado','Ahora el cliente podrá verlo','success');                
        } catch (error) {
            this.$log.info('error', error);
            this.$helper.showAxiosError(error, 'Error');
        }

    },
    pagina_anterior(){
      if(this.pagina_actual > 1)
        this.pagina_actual -= 1;
    },
    pagina_siguiente(){
      if(this.pagina_actual < this.$paginator.total_pages(this.comentarios_visibles, this.por_pagina))
      // if(this.pagina_actual < this.$paginator.total_paginas(this.comentarios_visibles, this.por_pagina)) //local
        this.pagina_actual += 1;
    },
    async leer_comentarios(){
      if(this.comentarios.length > 0){
        try {
          await apiClientes.leer_comentario_tickets(this.ticket.id);

        } catch (error) {
          this.$log.info('error',error);
          this.$helper.showAxiosError(error, 'Error');
        }
      }
    },
    notificar_comentarios_no_leidos(){
      let comentarios_no_leidos = [];

      if(this.comentarios.length > 0)
        this.comentarios.forEach(comentario => {
          if(comentario.leido_administrador == 0)
            comentarios_no_leidos.push(comentario);
        });

      if(comentarios_no_leidos.length > 0)
        this.$helper.showMessage('Hay comentarios nuevos','El cliente ha escrito algo importante','info', 'alert');            
    }        
  },
  computed:{
    comentarios_visibles(){
      let comentarios = []; 

      if(this.comentarios && this.comentarios.length > 0)
        this.comentarios.forEach(comentario => {
          comentarios.push(comentario);
        });
      else
        comentarios = [];

      return comentarios;
    },
    comentarios_paginados(){
      return this.$paginator.paginate_data(this.comentarios_visibles, this.pagina_actual, this.por_pagina, 'desc');
    },
    paginas_mostrar(){
      return this.$paginator.paginate_navigation(this.comentarios_visibles, this.pagina_actual, this.por_pagina, 3);
    },
  },
  watch:{
    nuevo_comentario(){
      if(this.nuevo_comentario !== null){
        this.comentario = this.nuevo_comentario;
        this.agregar_comentario();
      }

    },
    ticket(){
      this.obtener_comentarios();
    },
  },
  mounted(){
    this.comentarios = this.ticket.comentarios;
    this.notificar_comentarios_no_leidos();
  },
  destroyed(){
    this.leer_comentarios();
  },
  
  

}
</script>

<style lang="scss" scope>

.back-ground-comentario-sistema{
  background-color: #F9F871;
}

.back-ground-comentario-administrador{
  background-color: #5B8FC6;
}

.back-ground-comentario-cliente{
  background-color: #90E99E;
}

.font-nombre{
  font-size: 12px;
}


.pagina_actual {
  a {
    background-color: #C4E5C5;
  }
}
</style>